﻿import "../css/styles.scss";
import { inViewPort, ViewportObserver } from './ViewportObserver';
import { observeElementSize } from './elementSizeObserver';
import { HoverObserver } from './HoverObserver';
import { BlazorReconnectObserver } from './BlazorReconnectObserver';
import { saveAsFile } from './saveFile';
import { setHash } from './setHash';
import { debounceEvent } from './events';
import { initializeTabComponent, setTabComponentOverflow, setTabComponentOverflowByIdentifier } from './tabs';
import { submitForm } from './forms';
import { setTitle } from './setTitle';

declare global {
	interface Window {
		inViewPort: any;
		saveAsFile: any;
		setTitle: any;
		setHash: any;
		afterRouteChange: any;
		debounceEvent: any;
		initializeTabComponent: any;
		setTabComponentOverflow: any;
		setTabComponentOverflowByIdentifier: any;
		submitForm: any;
	}
}

window.addEventListener('resize', (e) => {
	var tabComponents = document.querySelectorAll('.veva-tabs');
	
	Array.from(tabComponents).forEach((tabComponent: Element) => {
		if (!tabComponent.classList.contains('veva-tabs--collapse-mobile')) {
			window.setTabComponentOverflow(tabComponent);
		}
	});	
});

window.addEventListener('load', (e) => {	

	observeElementSize();

	//* Setup up mutaion observer for new elements
	const config = { attributes: false, childList: true, subtree: true };
	const observer = new MutationObserver((mutations, observer) => {		
		ViewportObserver();
		HoverObserver();
	});
	observer.observe(document.getElementsByTagName("body")[0], config);

	ViewportObserver();
	HoverObserver();
	BlazorReconnectObserver();
});

window.inViewPort = inViewPort;
window.saveAsFile = saveAsFile;
window.setHash = setHash;
window.debounceEvent = debounceEvent;
window.initializeTabComponent = initializeTabComponent;
window.setTabComponentOverflow = setTabComponentOverflow;
window.setTabComponentOverflowByIdentifier = setTabComponentOverflowByIdentifier;
window.setTitle = setTitle;
window.submitForm = submitForm;